<template>
  <v-container>
    <v-row dense justify="center">
      <v-col cols="auto">
        <HowToPlay i18n_instructions_key="fortuneWheelInstruction" />
      </v-col>
    </v-row>
    <!-- select level -->
    <v-row
      v-if="this.getLevels.length > 1"
      no-gutters
      justify="center"
      class="pa-2"
    >
      <v-col class="pa-1" cols="auto" style="text-align: center">
        <span>
          <v-select
            v-model="selectedLevel"
            :items="this.getLevels"
            :item-text="text"
            item-value="value"
            @change="restartWithSelectedLevel()"
            dense
            filled
            hide-details="true"
          ></v-select>
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-row no-gutters justify="center">
        <v-col cols="auto" no-gutters>
          <canvas
            id="fortuneWheelCanvas"
            class="fortuneWheelDadaImage"
            :style="{ backgroundSize: dadaImageBackgroundSize }"
            :width="Math.min(canvasWidth, canvasHeight)"
            :height="Math.min(canvasWidth, canvasHeight)"
          >
            <p
              style="
                 {
                  color: white;
                }
              "
              align="center"
            >
              Sorry, your browser doesn't support canvas. Please try another.
            </p>
          </canvas>
        </v-col>
        <v-col cols="auto" no-gutters class="arrowLeft"></v-col>
      </v-row>
    </v-row>
    <v-row justify="center" align="center" class="pa-2">
      <v-btn
        :disabled="playing"
        color="secondary"
        raised="true"
        @click="spin()"
        >{{ $t('Spin') }}</v-btn
      >
    </v-row>
    <v-row
      v-if="!playing && atLeastOneSpin"
      justify="center"
      align="center"
      class="pa-2"
    >
      <v-btn color="secondary" @click="showHint = !showHint">{{
        showHint ? $t('Hide Possible Answer(s)') : $t('Show Possible Answer(s)')
      }}</v-btn>
    </v-row>
    <v-row v-if="showHint" no-gutters justify="center" class="pa-2">
      <!-- possible answer -->
      <v-card shaped>
        <v-card-title
          style="color: green"
          class="pa-2"
          v-for="(song, index) in selectedSong.answers"
          :key="index"
        >
          {{ song }}
        </v-card-title>
      </v-card>
    </v-row>
    <v-row><GoBack /></v-row>
  </v-container>
</template>

<script>
import GoBack from './GoBackButton.vue'
import HowToPlay from './HowToPlay'
import * as Winwheel from '../util/winwheel/Winwheel'
// Loads the tick audio sound in to an audio object.
const audio = new Audio(require('../assets/AsimJayJayKarTune.mp3'))
export default {
  mounted() {
    this.selectedLevel = this.$store.state.fortuneWheel.defaultLevel
    window.addEventListener('resize', this.restartWithSelectedLevel)
    this.restartWithSelectedLevel()
  },
  unmounted() {
    window.removeEventListener(
      'resize',
      setTimeout(() => {
        this.$forceUpdate()
        this.restartWithSelectedLevel()
      })
    )
  },
  components: {
    GoBack,
    HowToPlay
  },
  computed: {
    getLevels() {
      return this.$store.state.fortuneWheel.levels[
        this.$store.state.selectedLanguage
      ]
    }
  },
  methods: {
    restartWithSelectedLevel() {
      this.buildSongs()
      this.buildWheel()
    },
    buildSongs() {
      var songs = this.$store.state.fortuneWheel.pageData.filter(
        (q) => this.selectedLevel == q.level
      )
      this.songs = songs.map((a, i) => {
        return { fillStyle: this.colors[i], text: a.text }
      })
    },
    responsiveValuesRelativeToCanvasSize(maxTextLength, canvasSize) {
      const MINIMUM_CANVAS_SIZE = 320
      const targetCanvasSize = Math.max(canvasSize, 320)
      const fontSizeMultipliers = { 2: 0.8, 3: 0.7, 8: 0.5, 12: 0.35, 15: 0.3 }
      const innerRadius = canvasSize * 0.11
      var fontSizeMultiplier = Object.entries(fontSizeMultipliers).find(
        (k) => maxTextLength < k[0]
      )[1]
      var innerImageBackgroundSize = '20%'
      return {
        innerRadius: innerRadius,
        fontSize: 50 * fontSizeMultiplier * (canvasSize / MINIMUM_CANVAS_SIZE),
        dadaImageBackgroundSize: innerImageBackgroundSize
      }
    },
    buildWheel() {
      var maxTextLength = this.songs.reduce((a, b) =>
        Number(a.text.length) > Number(b.text.length) ? a : b
      ).text.length
      var responsiveValues = this.responsiveValuesRelativeToCanvasSize(
        maxTextLength,
        Math.min(this.canvasWidth, this.canvasHeight)
      )
      this.dadaImageBackgroundSize = responsiveValues.dadaImageBackgroundSize
      this.fortuneWheel = new Winwheel.Winwheel({
        pointerAngle: 90,
        innerRadius: responsiveValues.innerRadius,
        canvasId: 'fortuneWheelCanvas',
        responsive: true,
        textFontSize: responsiveValues.fontSize,
        textOrientation: 'horizontal',
        textAlignment: 'outer',
        textMargin: 15,
        textFontFamily: 'Ekatra',
        lineWidth: 2,
        strokeStyle: 'black',
        numSegments: this.songs.length,
        segments: this.songs,
        // Specify the animation to use.
        animation: {
          type: 'spinToStop',
          duration: 5,
          spins: 3,
          callbackFinished: this.alertPrize, // Function to call whent the spinning has stopped.
          callbackSound: this.playSound, // Called when the tick sound is to be played.
          soundTrigger: 'pin' // Specify pins are to trigger the sound.
        },
        // Turn pins on.
        pins: {
          number: 1,
          fillStyle: 'transparent',
          outerRadius: 4
        }
      })
    },
    playSound() {
      audio.play()
    },
    alertPrize(indicatedSegment) {
      audio.pause()
      this.playing = false
      this.selectedSong = this.$store.state.fortuneWheel.pageData.find(
        (s) => s.text === indicatedSegment.text
      )
      let winningSegmentNumber = this.fortuneWheel.getIndicatedSegmentNumber()
      for (let x = 1; x < this.fortuneWheel.segments.length; x++) {
        this.fortuneWheel.segments[x].fillStyle = 'gray'
      }
      this.fortuneWheel.segments[winningSegmentNumber].fillStyle =
        this.$vuetify.theme.themes.light.primary
      this.fortuneWheel.segments[winningSegmentNumber].textFillStyle = 'white'
      this.fortuneWheel.draw()
    },
    spin() {
      this.atLeastOneSpin = true
      this.showHint = false
      this.selectedSong = ''
      setTimeout(() => {
        this.playing = true
        this.buildWheel()
        this.fortuneWheel.animation.spins = 10
        this.fortuneWheel.startAnimation()
      }, 0)
    }
  },
  data: () => ({
    atLeastOneSpin: false,
    dadaImageBackgroundSize: '15%',
    canvasHeight: Math.max(320, window.innerHeight * 0.4),
    canvasWidth: Math.max(320, window.innerWidth * 0.8),
    fortuneWheel: null,
    selectedLanguage: 'guj',
    selectedLevel: null,
    languages: [
      { name: 'English', value: 'eng' },
      { name: 'Gujarati', value: 'guj' },
      { name: 'Hindi', value: 'hindi' }
    ],
    songs: [],
    selectedSong: '',
    playing: null,
    showHint: false,
    colors: [
      '#9dcccd',
      '#dad534',
      '#ef92de',
      '#70df5a',
      '#d2aceb',
      '#b0de41',
      '#94b5f1',
      '#e7bb34',
      '#5bcde9',
      '#eda546',
      '#88c4e9',
      '#d2ce61',
      '#c0c1de',
      '#5be18c',
      '#f19e80',
      '#55e1c0',
      '#e4b0bd',
      '#a4da73',
      '#6adbd6',
      '#e1bd87',
      '#91d79b',
      '#b3bea8',
      '#cbd68c',
      '#ceeccf',
      '#bec297'
    ]
  })
}
</script>
<style scoped>
.fortuneWheelDadaImage {
  background: url(../assets/dadasquare.jpg) center center no-repeat;
}
#fortuneWheelCanvas {
}
.arrowLeft {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  border-right: 1em solid var(--v-primary-base);
  margin-bottom: auto;
}
</style>
